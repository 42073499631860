import { useState, useContext, useEffect, useCallback, useMemo } from 'react';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { TokenInfoType } from '../../constants';
import { DropdownCex } from '../Dropdown';
import DropdownAsset from '../Dropdown/DropdownAsset';
import './index.css';
import DropdownWallet from '../Dropdown/DropDownWallet';
import IntegerInputWithTitle from '../Inputs/IntegerInputWithTitle';
import ThemeContext from '../../context/ThemeContext';
import { Error } from '../../constants/tokenTransferErrors';

interface TokenTransferProps {
  amount: string;
  address: string;
  selectedTokenInfo: TokenInfoType;
  tokensInfo: Array<TokenInfoType>;
  exchange: string;
  walletType: string;
  usingExchange: string;
  cexList: Array<string>;
  txError: Error;
  setExchange: (data: string) => void;
  setWalletType: (data: string) => void;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setAmount: (data: string) => void;
  setAddress: (data: string) => void;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  transferERC20: (isTxRecovery: boolean) => void;
  nativeTokenSymbols: Array<string>;
  setTxError: (data: Error) => void;
  resumingTransaction: boolean;
  setModalStatus: (data: boolean) => void;
}

enum ColorTheme {
  dark = 'dark',
  light = 'light'
}

const ErrorMessageMap = {
  [Error.NO_AMOUNT]:
    'Before you can PreSend this transaction, please select the amount of tokens you would like to send.',
  [Error.INSUFFICIENT_BALANCE]:
    'Before you can PreSend this transaction, Please double check if you have enough tokens.',
  [Error.CHECK_CEX]:
    'Before you can PreSend this transaction, please select if you are sending to a Centralized Exchange.',
  [Error.NO_WALLET]:
    'Before you can PreSend this transaction, please select the proper Wallet type from the list.',
  [Error.NO_CEX]:
    'Before you can PreSend this transaction, please select the proper Centralized Exchange from the list.',
  [Error.NO_RECEIVER]:
    'Before you can PreSend this transaction, please fill in the Recipient Address field.',
  [Error.INVALID_FORMAT]:
    'Sorry, the address you entered is not a valid address. Please check the address and try again.',
  [Error.NETWORK_ERROR]:
    'Sorry, we&apos;ve received too many requests in a short time. Please try again in a few minutes.',
  [Error.NEGATIVE_AMOUNT]: 'Sorry, you cannot send a negative amount of tokens.',
  [Error.INVALID_SELF_ADDRESS]:
    'Sorry, you cannot send a to your own address. Please enter a valid address.',
  [Error.NONE]: ''
};

const disabledRadio = (checked: boolean) => {
  const theme = createTheme({
    components: {
      MuiRadio: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: '#198754',
              height: ''
            }
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <Radio checked={checked} disabled />
    </ThemeProvider>
  );
};

function TokenTransfer({
  amount,
  address,
  tokensInfo,
  selectedTokenInfo,
  exchange,
  usingExchange,
  walletType,
  cexList,
  txError,
  setExchange,
  setWalletType,
  handleChange,
  setAmount,
  setAddress,
  setSelectedTokenInfo,
  transferERC20,
  nativeTokenSymbols,
  setTxError,
  resumingTransaction,
  setModalStatus
}: TokenTransferProps) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDark, setIsDark] = useState<boolean>(false);
  enum WalletOptions {
    CoinbaseWallet = 'coinbase_wallet',
    Metamask = 'metamask',
    TrustWallet = 'trust_wallet',
    OperaWallet = 'opera_wallet',
    CryptodotcomWallet = 'cryptodotcom_wallet',
    Ledger = 'ledger',
    Brave = 'brave_wallet',
    NotListed = 'not_listed'
  }
  const walletList = Object.values(WalletOptions);
  const { theme } = useContext(ThemeContext);
  let logoImage;

  const otherExchangeOrWallet = useMemo(
    () =>
      (usingExchange === 'yes' && exchange === 'not_listed') ||
      (usingExchange === 'no' && walletType === 'not_listed'),
    [exchange, walletType, usingExchange]
  );

  const decideTheme = useCallback(() => {
    if (theme === ColorTheme.dark) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [theme]);

  useEffect(() => {
    decideTheme();
  }, [theme, decideTheme]);

  useEffect(() => {
    setTxError(Error.NONE);
  }, [setTxError]);

  if ((!isHover && !isDark) || (!isHover && isDark)) {
    logoImage = <img src="/images/logo-white.png" alt="logo-black" className="presendLogo" />;
  } else {
    logoImage = <img src="/images/logo-black.png" alt="logo-white" className="presendLogo" />;
  }

  return (
    <div className="token-transfer-modal">
      <div className="modal-header">
        <h3>Ready to PreSend your transaction?</h3>
        <p>Please follow the steps below to PreSend your transaction.</p>
      </div>
      <div className="modal-body">
        <div className="token-select">
          <DropdownAsset
            selectedTokenInfo={selectedTokenInfo}
            setSelectedTokenInfo={setSelectedTokenInfo}
            setAmount={setAmount}
            tokensInfo={tokensInfo}
            disabled={resumingTransaction}
          />
        </div>
        <IntegerInputWithTitle
          title="How many tokens would you like to transfer?"
          amount={amount}
          setAmount={setAmount}
          errorMessage={ErrorMessageMap[txError]}
          error={
            txError === 'no-amount' ||
            txError === 'insufficient-balance' ||
            txError === 'negative-amount'
          }
          selectedTokenInfo={selectedTokenInfo}
          nativeTokenSymbols={nativeTokenSymbols}
          disabled={resumingTransaction}
        />
        <div className="cex-select-wrapper">
          <h3>Are you sending to a Centralized Exchange?</h3>
          <div className="yes-no">
            <FormControl sx={{ marginTop: '-9px' }}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                className="radio-group"
                value={usingExchange}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <FormControlLabel
                  value="yes"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px !important'
                    },
                    '& .MuiSvgIcon-root': {
                      // color: '#fff !important',
                    }
                  }}
                  control={
                    resumingTransaction ? (
                      disabledRadio(usingExchange === 'yes')
                    ) : (
                      <Radio className="dark:text-white" color="success" size="small" />
                    )
                  }
                  label="Yes"
                />
                <FormControlLabel
                  value="no"
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px !important'
                    }
                  }}
                  control={
                    resumingTransaction ? (
                      disabledRadio(usingExchange === 'no')
                    ) : (
                      <Radio
                        className="dark:text-white"
                        color="success"
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: 18
                          }
                        }}
                      />
                    )
                  }
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </div>
          {txError === 'check-cex' && (
            <div className="error-message">{ErrorMessageMap['check-cex']}</div>
          )}
          {usingExchange === 'yes' && (
            <div className="cex-select">
              <h3>Please select the type of Wallet you are sending to:</h3>
              <DropdownCex
                cexList={cexList}
                exchange={exchange}
                setExchange={setExchange}
                disabled={resumingTransaction}
              />
              {txError === 'no-cex' && (
                <div className="error-message">{ErrorMessageMap['no-cex']}</div>
              )}
            </div>
          )}
          {usingExchange === 'no' && (
            <div className="wallet-select">
              <h3>Please select the type of Wallet you are sending to:</h3>
              <DropdownWallet
                walletList={walletList}
                walletType={walletType}
                setWalletType={setWalletType}
                disabled={resumingTransaction}
              />
              {txError === 'no-wallet' && (
                <div className="error-message">{ErrorMessageMap['no-wallet']}</div>
              )}
            </div>
          )}
        </div>
        <div className="receiver-wrapper">
          <h3>What address do you want to send your tokens to?</h3>
          <div className="receiver">
            <p />
            <input
              type="text"
              className="form-control"
              placeholder="Receiver address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled={resumingTransaction || otherExchangeOrWallet}
            />
          </div>
          {txError === 'no-receiver' && (
            <div className="error-message">{ErrorMessageMap['no-receiver']}</div>
          )}
          {txError === 'invalid_format' && (
            <div className="error-message">{ErrorMessageMap.invalid_format}</div>
          )}
          {txError === 'invalid_self_address' && (
            <div className="error-message">{ErrorMessageMap.invalid_self_address}</div>
          )}
        </div>
      </div>
      {resumingTransaction ? (
        <div className="modal-footer">
          <div className="w-full flex flex-row gap-8">
            <button
              style={{ alignItems: 'center' }}
              type="button"
              onClick={() => transferERC20(true)}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              {logoImage}
            </button>
            <button
              className="text-center text-white"
              style={{ backgroundColor: '#EF3429' }}
              type="button"
              onClick={() => setModalStatus(false)}
            >
              <div className="m-auto font-bold text-lg">Cancel</div>
            </button>
          </div>
        </div>
      ) : (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => transferERC20(false)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            disabled={otherExchangeOrWallet}
          >
            {logoImage}
          </button>
        </div>
      )}
    </div>
  );
}

export default TokenTransfer;
