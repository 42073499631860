import { DISCORD_URL } from '../../../constants';

function Footer() {
  return (
    <div className="footer dark:bg-black">
      <span>Need Help? </span>
      <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
        Contact PreSend Support
      </a>
    </div>
  );
}

export default Footer;
