import { useContext } from 'react';
import { DropdownNetwork } from '../../Dropdown';
import ThemeContext from '../../../context/ThemeContext';

interface HeaderProps {
  chainId: string;
  logOut: () => Promise<void>;
  handleChainId: (__chainId: string) => void;
}

enum ColorTheme {
  dark = 'dark',
  light = 'light'
}

function Header({ chainId, logOut, handleChainId }: HeaderProps) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="header">
      <img
        className="logo"
        src={theme === ColorTheme.light ? '/images/logo.png' : '/images/logo-white.png'}
        alt=""
      />
      <DropdownNetwork chainId={chainId} handleChainId={handleChainId} />
      <button type="button" className="signout" onClick={() => logOut()}>
        Disconnect
      </button>
    </div>
  );
}

export default Header;
