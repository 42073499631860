export const serverUrl = 'https://qlyzsw5dyhrb.usemoralis.com:2053/server';
export const dexscreenerApi = 'https://api.dexscreener.com/latest/dex/tokens/';
export const appId = '6OaZVOmPwNyT3cmKtOIxGvSHZ5KTEwgjPNHZlc5P';

export const nftList = [
  {
    address: '0x1615600fE62ed38342F82eb9785029A2b1290DAF',
    chainId: 56,
    symbol: 'PRI NFT'
  },
  {
    address: '0x1052Dee9c5Ee04e12E488EaaB6BA7382726dAd30',
    chainId: 56,
    symbol: 'PII NFT'
  },
  {
    address: '0xCe8A6E03e6996f259191a18c4E2Aa398319b04E9',
    chainId: 137,
    symbol: 'TWF NFT'
  }
];

export const chains = [
  {
    origin: 'Ethereum Mainnet',
    name: 'eth',
    symbol: 'ETH',
    chainId: 1,
    chainIdHex: '0x1',
    rpcUrl:
      'https://rpc.ankr.com/eth/a87b278da5fc1f50a6c03779718cc7e7b08a2469dde48b3e42c000eaab37c4f2',
    tokenList:
      'https://raw.githubusercontent.com/viaprotocol/tokenlists/main/tokenlists/ethereum.json',
    scanApi: {
      baseUrl: 'https://api.etherscan.io/api',
      path: '?module=account&action=tokentx&address=walletAddr&page=1&offset=100&startblock=0&endblock=blockNumber&sort=asc&apikey=B3HD76NSM9XGM1MKAWFQCWMU8GPRAUD1I1'
    },
    blockExplorerUrl: 'https://etherscan.io'
  },
  {
    origin: 'BNB Smart Chain Mainnet',
    name: 'bsc',
    symbol: 'BNB',
    chainId: 56,
    chainIdHex: '0x38',
    rpcUrl:
      'https://rpc.ankr.com/bsc/a87b278da5fc1f50a6c03779718cc7e7b08a2469dde48b3e42c000eaab37c4f2',
    tokenList: 'https://raw.githubusercontent.com/viaprotocol/tokenlists/main/tokenlists/bsc.json',
    scanApi: {
      baseUrl: 'https://api.bscscan.com/api',
      path: '?module=account&action=tokentx&address=walletAddr&page=1&offset=100&startblock=0&endblock=blockNumber&sort=asc&apikey=8DEQWQBNZ1EDG4N148CRT8ADGZQ99UIFBU'
    },
    blockExplorerUrl: 'https://bscscan.com'
  },
  {
    origin: 'Avalanche',
    name: 'avax',
    symbol: 'AVAX',
    chainId: 43114,
    chainIdHex: '0xA86A',
    rpcUrl:
      'https://rpc.ankr.com/avalanche/a87b278da5fc1f50a6c03779718cc7e7b08a2469dde48b3e42c000eaab37c4f2',
    tokenList: 'https://raw.githubusercontent.com/viaprotocol/tokenlists/main/tokenlists/avax.json',
    scanApi: {
      baseUrl: 'https://api.snowtrace.io/api',
      path: '?module=account&action=tokentx&address=walletAddr&page=1&offset=100&startblock=0&endblock=blockNumber&sort=asc&apikey=NVKZQAER9JB6ZE3DJYHYQS9C72WIX86TC1'
    },
    blockExplorerUrl: 'https://snowtrace.io'
  },
  {
    origin: 'Fantom',
    name: 'ftm',
    symbol: 'FTM',
    chainId: 250,
    chainIdHex: '0xFA',
    rpcUrl:
      'https://rpc.ankr.com/fantom/a87b278da5fc1f50a6c03779718cc7e7b08a2469dde48b3e42c000eaab37c4f2',
    tokenList: 'https://raw.githubusercontent.com/viaprotocol/tokenlists/main/tokenlists/ftm.json',
    // scanApiUrl:
    //   'https://api.ftmscan.com/api?module=account&action=tokentx&address=walletAddr&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=4VNFVKVNHBWPDSQ4SNC5I4TBCF9TWE33SH',
    scanApi: {
      baseUrl: 'https://api.ftmscan.com/api',
      path: '?module=account&action=tokentx&address=walletAddr&startblock=0&endblock=blockNumber&page=1&offset=100&sort=asc&apikey=4VNFVKVNHBWPDSQ4SNC5I4TBCF9TWE33SH'
    },
    blockExplorerUrl: 'https://ftmscan.com'
  },
  {
    origin: 'Polygon',
    name: 'matic',
    symbol: 'MATIC',
    chainId: 137,
    chainIdHex: '0x89',
    rpcUrl:
      'https://rpc.ankr.com/polygon/a87b278da5fc1f50a6c03779718cc7e7b08a2469dde48b3e42c000eaab37c4f2',
    tokenList:
      'https://raw.githubusercontent.com/viaprotocol/tokenlists/main/tokenlists/polygon.json',
    scanApi: {
      baseUrl: 'https://api.polygonscan.com/api',
      path: '?module=account&action=tokentx&address=walletAddr&page=1&offset=100&startblock=0&endblock=blockNumber&sort=asc&apikey=5XRNSD5U8K3PT8CQ7GYKFYI2ZD3C86J8ZS'
    },
    blockExplorerUrl: 'https://polygonscan.com'
  }
];

export const browserExtensionIDs = {
  CHROME: 'nkbihfbeogaeaoehlefnkodbefgpgknn',
  FIREFOX: 'webextension@metamask.io',
  OPERA: 'djclckkglechooblngghdinmeemkbgci',
  EDGE: 'ejbalbakoplchlghecdalmeeeajnimhm'
};
