import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { CircularProgress } from '@mui/material';
import './index.css';
import { EMPTY_VALUE } from '../../constants';

interface ProcessingModalProps {
  text: string;
  loading: boolean;
  txResultStatus: boolean;
  handleClose: () => void;
}

function ProcessingModal({ text, loading, txResultStatus, handleClose }: ProcessingModalProps) {
  return (
    <Modal open={loading} onClose={() => (txResultStatus ? handleClose() : {})}>
      <div className="proccessing-success-modal">
        {!txResultStatus ? (
          <div className="proccessing-modal">
            <div className="spinner-wrap">
              <CircularProgress
                sx={{
                  color: '#21c168',
                  animation: '4s linear 0s infinite normal none running animation-61bdi0;'
                }}
                size={110}
              />
              <img className="logo" src="/images/logo-icon.png" alt="" />
            </div>
            <Typography
              sx={{
                fontFamily: 'Inter !important',
                marginTop: '13px',
                fontSize: '24px',
                lineHeight: '25px',
                fontWeight: '700',
                color: '#37A645'
              }}
            >
              {text}
            </Typography>
            <Typography
              sx={{
                marginTop: '15px',
                fontSize: '10px',
                lineHeight: '12px',
                fontWeight: '500',
                color: '#9C9C9C'
              }}
            >
              Do not close the PreSend extension while we process.
              {EMPTY_VALUE}
              <br />
              This may take us a few seconds.
            </Typography>
          </div>
        ) : (
          <div className="success-modal">
            <img src="/images/tx-success.png" alt="success" />
            <div className="title">CONGRATULATIONS!</div>
            <div className="des">
              You can safely send your transaction to the desired recipient wallet!
            </div>
            <div className="info">
              Do not close the PreSend extension while we process your transaction.
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ProcessingModal;
