import { useState } from 'react';
import './index.css';

interface SwitchProps {
  handleSwitchValue: (e: string) => void;
}

function ActivitySwitch({ handleSwitchValue }: SwitchProps) {
  const [value, setValue] = useState<string>('timeline');

  const changeValue = (e: string) => {
    setValue(e);
    handleSwitchValue(e);
  };

  return (
    <div className="activity-switch">
      <button
        type="button"
        className={`${value === 'timeline' && 'active'}`}
        onClick={() => changeValue('timeline')}
      >
        Timeline
      </button>
      <button
        type="button"
        className={`${value === 'status' && 'active'}`}
        onClick={() => changeValue('status')}
      >
        Status
      </button>
    </div>
  );
}

export default ActivitySwitch;
