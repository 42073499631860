import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAccount } from 'wagmi';
import store from './store';
import { connectWallet } from './actions';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ThemeContext from './context/ThemeContext';

enum ColorTheme {
  dark = 'dark',
  light = 'light'
}

function App() {
  const [theme, setTheme] = useState('');
  useEffect(() => {
    if (localStorage.getItem('theme')) {
      if (localStorage.getItem('theme') === ColorTheme.dark) {
        document.documentElement.classList.add(ColorTheme.dark);
        setTheme(ColorTheme.dark);
      } else {
        document.documentElement.classList.remove(ColorTheme.dark);
        setTheme(ColorTheme.light);
      }
    } else {
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (prefersDark) {
        setTheme(ColorTheme.dark);
        localStorage.setItem('theme', ColorTheme.dark);
        document.documentElement.classList.add(ColorTheme.dark);
      } else {
        setTheme(ColorTheme.light);
        localStorage.setItem('theme', ColorTheme.light);
        document.documentElement.classList.remove(ColorTheme.dark);
      }
    }
  }, []);

  const toggleTheme = useCallback(() => {
    if (theme === ColorTheme.light) {
      localStorage.setItem('theme', ColorTheme.dark);
      setTheme(ColorTheme.dark);
      document.documentElement.classList.add(ColorTheme.dark);
    } else {
      localStorage.setItem('theme', ColorTheme.light);
      setTheme(ColorTheme.light);
      document.documentElement.classList.remove(ColorTheme.dark);
    }
  }, [theme]);

  const memoizedThemeContextValue = useMemo(() => ({ theme, toggleTheme }), [theme, toggleTheme]);

  const { address, isConnected } = useAccount();
  if (isConnected) {
    store.dispatch(connectWallet(address || ''));
    localStorage.setItem('WALLET_CONNECTED', 'on');
  }

  return (
    <ThemeContext.Provider value={memoizedThemeContextValue}>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
      <ToastContainer />
    </ThemeContext.Provider>
  );
}

export default App;
