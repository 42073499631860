import ActivitySwitch from '../../ActivitySwitch';
import ActivityFilter from '../../ActivityFilter';

interface ActivityFilterWrapperProps {
  handleSwitchValue: (value: string) => void;
  handleFilterValue: (value: string) => void;
  desc: boolean;
  setDesc: (value: boolean) => void;
  activeSwitch: string;
  ActivitySortType: any;
}

function ActivityFilterWrapper({
  handleSwitchValue,
  handleFilterValue,
  desc,
  setDesc,
  activeSwitch,
  ActivitySortType
}: ActivityFilterWrapperProps) {
  return (
    <div className="sort-filter-bar">
      <div className="sort-bar">
        <p>Sort activity by:</p>
        <ActivitySwitch handleSwitchValue={handleSwitchValue} />
        <button type="button" className="sort-by" onClick={() => setDesc(!desc)}>
          <p>Default</p>
          <img src="/images/sort-arrow.png" alt="sort-arrow" />
        </button>
      </div>
      {activeSwitch === ActivitySortType.Status && (
        <div className="filter-bar">
          <ActivityFilter handleFilterValue={handleFilterValue} />
        </div>
      )}
    </div>
  );
}

export default ActivityFilterWrapper;
