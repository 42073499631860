import { useEffect, useMemo, useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { bigNumberToString, scientificToDecimal } from '../../../helpers/methods';
import { TokenInfoType } from '../../../constants';

interface IntegerInputWithTitleProps {
  title: string;
  amount: string;
  setAmount: (data: string) => void;
  error: boolean;
  errorMessage: string;
  selectedTokenInfo: TokenInfoType;
  nativeTokenSymbols: Array<string>;
  disabled: boolean;
}

function IntegerInputWithTitle({
  title,
  amount,
  setAmount,
  errorMessage,
  error,
  selectedTokenInfo,
  nativeTokenSymbols,
  disabled
}: IntegerInputWithTitleProps) {
  const [showMaxBtn, setShowMaxBtn] = useState<boolean>(false);

  useEffect(() => {
    if (nativeTokenSymbols.includes(selectedTokenInfo.symbol)) {
      setShowMaxBtn(false);
    } else {
      setShowMaxBtn(true);
    }
  }, [nativeTokenSymbols, selectedTokenInfo]);

  // TODO: Determine if we want to use the current value or a value set by the user
  // If we use a value set by the user then we'll have to store the set value separate from the
  // step amount some how. Not sure exactly how to do that.
  // Thomas Brown: 2024-01-22
  const stepAmount = useMemo(() => {
    const str = amount.toString();
    const dotIndex = str.indexOf('.');

    if (dotIndex === -1) {
      return 1;
    }

    const decLen = str.length - dotIndex - 1;
    const decPos = 10 ** decLen;
    return 1 / decPos;
  }, [amount]);

  return (
    <div className="token-max">
      <h3>{title}</h3>
      {showMaxBtn && (
        <button
          type="button"
          className="btn-max"
          disabled={disabled}
          onClick={() => {
            setAmount(
              bigNumberToString(selectedTokenInfo.balance, selectedTokenInfo.decimals, true)
            );
          }}
        >
          MAX
        </button>
      )}
      <input
        type="number"
        className="token-amount"
        value={amount}
        placeholder="0"
        disabled={disabled}
        step={stepAmount}
        onChange={(e: any) => {
          let newValue = e.target.value;

          if (newValue.length === 0) {
            setAmount(newValue);
            return;
          }

          // NOTE: We do not want negative numbers
          // Thomas Brown: 2024-01-22
          if (newValue[0] === '-') {
            return;
          }

          const dotIndex = newValue.indexOf('.');
          if (dotIndex === -1 && newValue[0] === '0' && newValue.length > 1) {
            return;
          }

          if (newValue.includes('e') || newValue.includes('E')) {
            newValue = scientificToDecimal(newValue);
          }

          // NOTE: We only want a maximum of 8 decimal of precision on input boxes
          // NOTE: We have `> 9` because the decimal point increases the size by 1
          // Github Issue: https://github.com/Presend-DeFi/presend-web/issues/1
          // Thomas Brown: 2024-01-22
          if (dotIndex > -1 && newValue.length - dotIndex > 9) {
            return;
          }

          if (selectedTokenInfo.balance.eq(0)) {
            setAmount('0');
            return;
          }

          // Here we match up the number of decimals with with selectedTokenInfo.decimals so that
          // we can do a .gt compare to make sure we don't go over the actual balance amount.
          // NOTE: Consider moving this to a helper if it's need to be used else where.
          // Thomas Brown: 2024-01-22
          const maxAllowed = {
            balance: selectedTokenInfo.balance,
            decimals: selectedTokenInfo.decimals
          };
          const curValue = {
            balance: BigNumber.from(newValue.replace('.', '')),
            decimals: dotIndex === -1 ? 0 : newValue.length - dotIndex - 1
          };

          if (curValue.decimals > maxAllowed.decimals) {
            const pow = curValue.decimals - maxAllowed.decimals;
            maxAllowed.decimals += pow;
            maxAllowed.balance = maxAllowed.balance.mul(1 ** pow);
            console.log('more', pow);
          } else if (curValue.decimals < maxAllowed.decimals) {
            const pow = maxAllowed.decimals - curValue.decimals;
            curValue.decimals += pow;
            curValue.balance = curValue.balance.mul(1 ** pow);
            console.log('less', pow);
          }

          console.log(curValue, maxAllowed, newValue.length - dotIndex - 1);

          if (curValue.balance.gt(maxAllowed.balance)) {
            setAmount(bigNumberToString(maxAllowed.balance, maxAllowed.decimals, true));
          } else {
            setAmount(newValue);
          }
        }}
      />
      {error && <div className="error-message">{errorMessage}</div>}
    </div>
  );
}

export default IntegerInputWithTitle;
