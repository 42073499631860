export enum Error {
  NO_AMOUNT = 'no-amount',
  NEGATIVE_AMOUNT = 'negative-amount',
  INSUFFICIENT_BALANCE = 'insufficient-balance',
  CHECK_CEX = 'check-cex',
  NO_WALLET = 'no-wallet',
  NO_CEX = 'no-cex',
  NO_RECEIVER = 'no-receiver',
  NETWORK_ERROR = 'network_error',
  INVALID_FORMAT = 'invalid_format',
  INVALID_SELF_ADDRESS = 'invalid_self_address',
  NONE = ''
}

export default Error;
