import { CONNECT_WALLET, DISCONNECT_WALLET } from '../constants/actionTypes';
/* eslint-disable  @typescript-eslint/no-explicit-any */

const INITIAL_STATE = {
  walletAddress: ''
};

const reducer = (state = INITIAL_STATE, action = null as any) => {
  switch (action.type) {
    case CONNECT_WALLET:
      return {
        ...state,
        walletAddress: action.walletAddr
      };
    case DISCONNECT_WALLET:
      return {
        ...state,
        walletAddress: ''
      };
    default:
      return state;
  }
};

export default reducer;
