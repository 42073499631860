import { useState } from 'react';
import copy from 'copy-to-clipboard';
import CheckIcon from '@mui/icons-material/Check';
import { delay } from '../../../helpers/methods';

interface AccountWrapperProps {
  walletAddress: string;
}

function AccountWrapper({ walletAddress }: AccountWrapperProps) {
  const [toolTipText, setToolTipText] = useState<string>('Copy to clipboard');
  const [toolTipLoading, setToolTipLoading] = useState<boolean>(false);

  const handleToolTip = async () => {
    setToolTipLoading(true);
    setToolTipText('Copied');
    copy(walletAddress);
    await delay(2000);
    setToolTipLoading(false);
  };

  return (
    <div className="account-wrapper">
      <h3>Account</h3>
      <div>
        <div>
          {walletAddress.substr(0, 16)}
          ...
          {walletAddress.substr(walletAddress.length - 4, 4)}
        </div>
        <button type="button" onClick={handleToolTip}>
          {toolTipLoading ? (
            <CheckIcon sx={{ fontSize: '14px', color: '#2ea043', fontWeight: '600' }} />
          ) : (
            <img src="/images/copy-icon.png" alt="copy" />
          )}
        </button>
        {toolTipLoading && <span className="tooltiptext">{toolTipText}</span>}
      </div>
    </div>
  );
}

export default AccountWrapper;
