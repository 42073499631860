import './index.css';
import { useContext } from 'react';
import ReactSwitch from 'react-switch';
import ThemeContext from '../../../context/ThemeContext';

enum ColorTheme {
  dark = 'dark',
  light = 'light'
}

function DarkModeSwitch() {
  const { theme, toggleTheme } = useContext(ThemeContext);
  return (
    <div className="dark-mode-container">
      <div className="label">Appearance</div>
      <ReactSwitch onChange={toggleTheme} checked={theme === ColorTheme.dark} />
    </div>
  );
}

export default DarkModeSwitch;
