import { useEffect, useRef } from 'react';
import { BigNumber } from 'bignumber.js';
import { BigNumber as BigNumber1 } from '@ethersproject/bignumber';
import { PreSendTransaction } from '@presend/verification-dev';
import { TokenInfoType } from '../constants';

export default function useInterval(callback: () => void, delay: null | number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const { current } = savedCallback;
      return current && current();
    }

    if (delay !== null) {
      tick();
      const id = setInterval(tick, delay);
      clearInterval(id);
    }
  }, [delay]);
}

export const bigNumberToString = (
  value: BigNumber1,
  mantissa: number,
  precise: boolean
): string => {
  const result: BigNumber = new BigNumber(value.toString()).div(new BigNumber(10).pow(mantissa));

  let display: string;

  if (precise) {
    display = result.toFixed(8, BigNumber.ROUND_DOWN);
  } else if (result.gte(new BigNumber(1))) {
    display = result.toFixed(2, BigNumber.ROUND_DOWN);
  } else {
    display = result.toFixed(5, BigNumber.ROUND_DOWN);
  }

  const [whole, decimal] = display.split('.');

  const decimalArr = decimal.split('');
  while (decimalArr.length > 0 && decimalArr[decimalArr.length - 1] === '0') {
    decimalArr.pop();
  }

  const trimedDecimal = decimalArr.join('');

  return trimedDecimal.length === 0 ? whole : `${whole}.${trimedDecimal}`;
};

export const delay = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export const abbreviateNumber = (value: number) => {
  let result = '';
  if (value >= 10 ** 6) {
    const suffixes = ['', 'M', 'B', 'T'];
    const suffixNum: number = Math.floor(value.toString().length / 6);
    let shortValue: string = parseFloat(
      (suffixNum !== 0 ? (value / 10 ** 6) * suffixNum : value).toPrecision(2)
    ).toString();
    if (Number(shortValue) % 1 !== 0) {
      shortValue = Number(shortValue).toFixed(2);
    }
    result = shortValue + suffixes[suffixNum];
  } else {
    result = Number(value.toFixed(2)).toLocaleString();
  }
  return result;
};

export function convertPresendTransactionToTokenInfo(
  transaction: PreSendTransaction
): TokenInfoType {
  const tokenInfo: TokenInfoType = {
    chain: transaction.targetChainId,
    name: transaction.targetTokenSymbol,
    decimals: 18,
    symbol: transaction.targetTokenSymbol,
    balance: BigNumber1.from(transaction.targetTokenAmount),
    address: '',
    price: transaction.tokenPriceUsd?.toFixed(2) ?? 0
  };

  return tokenInfo;
}

export function isValidEVMAddress(address: string): boolean {
  const regex = /^0x[a-fA-F0-9]{40}$/;
  return regex.test(address);
}

export function isValidEnsAddress(name: string): boolean {
  return name.endsWith('.eth');
}

export const scientificToDecimal = (num: number): string => {
  const nsign = Math.sign(num);
  // Remove the sign
  const absoluteNum = Math.abs(num);
  let result: string = absoluteNum.toString();

  // If the number is in scientific notation, convert it
  if (/\d+\.?\d*e[+-]*\d+/i.test(result)) {
    const zero = '0';
    const parts = result.toLowerCase().split('e'); // Split into coefficient and exponent
    const e = parseInt(parts.pop() ?? '', 10); // Store the exponential part and ensure it's not undefined
    let l = Math.abs(e); // Get the number of zeros
    const sign = e / l;
    const coeffArray = parts[0].split('.');

    if (sign === -1) {
      l -= coeffArray[0].length;
      if (l < 0) {
        result = `${coeffArray[0].slice(0, l)}.${coeffArray[0].slice(
          l
        )}${coeffArray.length === 2 ? coeffArray[1] : ''}`;
      } else {
        result = `${zero}.${new Array(l + 1).join(zero)}${coeffArray.join('')}`;
      }
    } else {
      const dec = coeffArray[1];
      if (dec) {
        l -= dec.length;
      }
      if (l < 0) {
        result = `${coeffArray[0] + dec.slice(0, l)}.${dec.slice(l)}`;
      } else {
        result = coeffArray.join('') + new Array(l + 1).join(zero);
      }
    }
  }

  return nsign < 0 ? `-${result}` : result;
};
