import moment from 'moment';
import { BigNumber } from '@ethersproject/bignumber';
import { PreSendTransaction, TransactionStatus } from '@presend/verification-dev';
import { hashCode, intToRGB } from '../../../config/helper';
import { bigNumberToString } from '../../../helpers/methods';
import { EMPTY_VALUE } from '../../../constants';

interface ActivityProps extends PreSendTransaction {
  txType: string;
}

interface ActivityButtonProps {
  item: ActivityProps;
  walletAddress: string;
  openResumeTransactionModal: (transactionItem: ActivityProps) => void;
}

enum Routes {
  TO = 'TO',
  FROM = 'FROM'
}

function ActivityButton({ item, walletAddress, openResumeTransactionModal }: ActivityButtonProps) {
  let route = '';
  if (walletAddress.toLocaleLowerCase() === item.sourceAddress) {
    route = Routes.TO;
  } else if (walletAddress.toLocaleLowerCase() === item.targetAddress) {
    route = Routes.FROM;
  }

  const handleIncomplete = () => {
    console.log(item.status);
    if (item.status !== TransactionStatus.Verified) {
      openResumeTransactionModal(item);
    }
  };

  return (
    <button
      type="button"
      className="activity"
      key={item.transactionId}
      onClick={() => {
        handleIncomplete();
      }}
    >
      <div className="token">
        <div className="icon">
          {item.targetTokenAddress === '' ? (
            <img
              className="symbol"
              src={`/images/${item.targetTokenSymbol.toLowerCase()}.png`}
              alt=""
            />
          ) : (
            <p
              style={{
                background: `#${intToRGB(hashCode(item.targetTokenAddress))}`
              }}
            />
          )}
        </div>
        <div className="tx-time">
          <div className="tx">
            <span>
              {route}:{EMPTY_VALUE}
            </span>
            <div>
              {item.targetAddress.substr(0, 7)}
              ...
              {item.targetAddress.substr(item.targetAddress.length - 4, 4)}
            </div>
          </div>
          <div className="timestamp">
            <div className="time">{moment(item.created).fromNow()}</div>
            <div className="tx-type">
              <span className={item.txType}>{item.txType.toUpperCase()}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="balance-fee">
        <div className="balance">
          {bigNumberToString(BigNumber.from(item.targetTokenAmount), 18, false)}
          {EMPTY_VALUE}
          {item.targetTokenSymbol}
        </div>
        <div className="price">Fee: ${item.feePaidUsd}</div>
      </div>
    </button>
  );
}

export default ActivityButton;
