import { BigNumber } from '@ethersproject/bignumber';

export interface TokenInfoType {
  chain: string;
  name: string;
  decimals: number;
  symbol: string;
  balance: BigNumber;
  address: string;
  price: string;
}
export const DISCORD_URL = 'https://discord.com/invite/P5KTUXT9Tf';
export const EMPTY_VALUE = ' ';
