import { useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDetectOutsideClick } from '../../../hooks';
import { bigNumberToString } from '../../../helpers/methods';
import { EMPTY_VALUE, TokenInfoType } from '../../../constants';
import { hashCode, intToRGB } from '../../../config/helper';
import './index.css';

interface DropdownAssetProps {
  tokensInfo: TokenInfoType[];
  selectedTokenInfo: TokenInfoType;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  disabled: boolean;
}

enum KnownTokens {
  USDC = 'USDC',
  USDT = 'USDT',
  BUSD = 'BUSD'
}

function DropdownAsset({
  selectedTokenInfo,
  setSelectedTokenInfo,
  setAmount,
  tokensInfo,
  disabled
}: DropdownAssetProps) {
  const dropDownRef = useRef(null);
  const [showDropdown, toggleDropdown] = useDetectOutsideClick(dropDownRef, false);

  return (
    <button
      type="button"
      className="token-select"
      ref={dropDownRef}
      disabled={disabled}
      onClick={() => toggleDropdown(!showDropdown)}
      onKeyDown={() => toggleDropdown(!showDropdown)}
    >
      <button type="button" key={selectedTokenInfo.symbol} className="token-title">
        <div className="token">
          {selectedTokenInfo.address === '' || selectedTokenInfo.symbol in KnownTokens ? (
            <img
              className="symbol"
              src={`/images/${selectedTokenInfo.symbol.toLowerCase()}.png`}
              alt=""
            />
          ) : (
            <i style={{ background: `#${intToRGB(hashCode(selectedTokenInfo.address))}` }} />
          )}
          <div className="name">
            {selectedTokenInfo.symbol.length > 10
              ? `${selectedTokenInfo.symbol.substr(0, 10)}...`
              : selectedTokenInfo.symbol}
          </div>
          <div className="price">
            {bigNumberToString(selectedTokenInfo.balance, selectedTokenInfo.decimals, false)}
          </div>
        </div>
        <div className="arrow">
          <KeyboardArrowDownIcon fontSize="inherit" color="inherit" />
        </div>
      </button>
      {showDropdown && (
        <div className="assets-wrap">
          <ul className="assets-list">
            {tokensInfo.map((token: TokenInfoType) => (
              <li key={token.symbol}>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedTokenInfo({ ...token });
                    setAmount('');
                  }}
                >
                  {token.address === '' || token.symbol in KnownTokens ? (
                    <img
                      className="symbol"
                      src={`/images/${token.symbol.toLowerCase()}.png`}
                      alt=""
                    />
                  ) : (
                    <i style={{ background: `#${intToRGB(hashCode(token.address))}` }} />
                  )}
                  <span>
                    {token.symbol.length > 10 ? `${token.symbol.substr(0, 10)}...` : token.symbol}
                  </span>
                  <span>
                    {bigNumberToString(token.balance, token.decimals, false)}
                    {EMPTY_VALUE}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </button>
  );
}

export default DropdownAsset;
