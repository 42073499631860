import { hashCode, intToRGB } from '../../../config/helper';
import { bigNumberToString } from '../../../helpers/methods';
import { EMPTY_VALUE, TokenInfoType } from '../../../constants';

interface AssetButtonProps {
  isCustomNft: boolean;
  token: TokenInfoType;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  fillInputs: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  setModalStatus: (status: boolean) => void;
  setResumingTransaction: (resuming: boolean) => void;
}

enum KnownTokens {
  USDC = 'USDC',
  USDT = 'USDT',
  BUSD = 'BUSD'
}

function AssetButton({
  isCustomNft,
  token,
  setSelectedTokenInfo,
  fillInputs,
  setAmount,
  setModalStatus,
  setResumingTransaction
}: AssetButtonProps) {
  return (
    <button
      type="button"
      className="asset"
      key={token.symbol}
      onClick={() => {
        setSelectedTokenInfo({ ...token });
        fillInputs(token);
        setAmount('');
        setModalStatus(true);
        setResumingTransaction(false);
      }}
    >
      <div className="token">
        <div className="icon">
          {token.address === '' || token.symbol in KnownTokens ? (
            <img className="symbol" src={`/images/${token.symbol.toLowerCase()}.png`} alt="" />
          ) : (
            <p style={{ background: `#${intToRGB(hashCode(token.address))}` }} />
          )}
        </div>
        <div className="name-symbol">
          <div className="name">
            {token.name.length > 10 ? `${token.name.substr(0, 10)}...` : token.name}
          </div>
          {isCustomNft && <div className="symbol">{token.symbol}</div>}

          {!isCustomNft && (
            <div className="symbol">
              {token.symbol.length > 10 ? `${token.symbol.substr(0, 10)}...` : token.symbol}
            </div>
          )}
        </div>
      </div>
      <div className="balance-price">
        <div className="balance">{bigNumberToString(token.balance, token.decimals, false)}</div>
        {isCustomNft && <div className="price"> </div>}
        {!isCustomNft && (
          <div className="price">
            ${token.price}
            {EMPTY_VALUE}
            USD
          </div>
        )}
      </div>
    </button>
  );
}

export default AssetButton;
