import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSwitchNetwork, useNetwork, useDisconnect, useSigner } from 'wagmi';
import { providers, utils, Contract } from 'ethers';
import axios from 'axios';
import BlueBirdPromise from 'bluebird';
import BigNumber from 'bignumber.js';
import { BigNumber as BigNumber1 } from '@ethersproject/bignumber';
import {
  PresendVerificationSDK,
  PricingUtility,
  TransactionUtility,
  PreSendTransaction,
  VerificationResponse,
  VerificationStepResponse,
  VerificationStepId
} from '@presend/verification-dev';
import { chains, nftList } from '../config/config';
import { warnToast } from '../helpers/toast';
import useInterval, { bigNumberToString, delay, abbreviateNumber } from '../helpers/methods';
import { disconnectWallet } from '../actions';

import ERC20ABI from '../constants/abis/erc20';
import ERC721ABI from '../constants/abis/erc721';
import { TokenInfoType } from '../constants';
import DashboardComponent from '../components/Dashboard';
import transactionFetcher from '../utils/transactionsFetcher';
import { Error } from '../constants/tokenTransferErrors';
import { ActivityProps } from '../components/Layouts/AssetsActivityWrapper';
import { WarningModalEnum } from '../components/WarningModal';
import { LogLevel } from '../logging/core/LogLevel';
import DefaultLogger from '../logging/loggers/DefaultLogger';

const logger = new DefaultLogger();
const componentName = 'Dashboard.tsx';

const pricing = new PricingUtility({
  debug: true
});

let tokenContract: Contract;

interface ChainType {
  origin: string;
  name: string;
  symbol: string;
  chainId: number;
  chainIdHex: string;
  rpcUrl: string;
  tokenList: string;
  scanApi: {
    baseUrl: string;
    path: string;
  };
  blockExplorerUrl: string;
}

interface TxType {
  contractAddress: string;
}

interface SupportedAsset {
  supportedNetworkId: string;
  networkSlug: string;
  networkName: string;
  chainId: string;
  nativeTokenSymbol: string;
  rpcUrl: string;
  presendPaymentContractAddress: string;
  presendNftPriContractAddress: string;
  presendNftPiiContractAddress: string;
  created: string;
  updated: string;
}

interface SupportedAssetsResponse {
  content: SupportedAsset[];
  success: boolean;
}

enum WalletOptions {
  CoinbaseWallet = 'coinbase_wallet',
  Metamask = 'metamask',
  TrustWallet = 'trust_wallet',
  OperaWallet = 'opera_wallet',
  CryptodotcomWallet = 'cryptodotcom_wallet',
  Ledger = 'ledger',
  Brave = 'brave_wallet',
  NotListed = 'not_listed'
}

async function GetNativeTokenSymbolsAsync(): Promise<string[]> {
  const ctx = {
    component: 'src/pages/Dashboard.GetNativeTokenSymbolsAsync'
  };

  const url = 'https://verification-dev-us.presend.io/supportedAssets/networks';
  const response = await fetch(url);

  logger.log({
    level: LogLevel.INFO,
    message: 'Getting Native Token Symbols',
    component: componentName,
    context: { ...ctx, url, response: response.ok }
  });

  if (response.ok) {
    const data: SupportedAssetsResponse = await response.json();
    const nativeTokenSymbols = data.content.map((asset) => asset.nativeTokenSymbol);
    const distinctNativeTokenSymbols = [...new Set(nativeTokenSymbols)];
    return distinctNativeTokenSymbols;
  }

  logger.error({
    message: 'Failed to fetch supported assets',
    error: 'Unexpected error while getting the assets',
    component: componentName,
    context: ctx
  });
  console.error('Failed to fetch supported assets');
  return [];
}

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // state variables
  const [loading, setLoading] = useState(false);
  const [txResultStatus, setTxResultStatus] = useState(false);
  const [loadingText, setLoadingText] = useState('Processing Transaction');
  const [reload, setReload] = useState<boolean>(false);
  const { data: signer } = useSigner();
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [txProcessing, setTxProcessing] = useState(false);
  const [modalStatus, setModalStaus] = useState<boolean>(false);
  const [warningModalStatus, setWarningModalStatus] = useState<boolean>(false);
  const [warningModalEnum, setWarningModalEnum] = useState<WarningModalEnum>(
    WarningModalEnum.OperationFailed
  );
  const [mainTokenInfo, setMainTokenInfo] = useState<TokenInfoType>({
    chain: '',
    name: '',
    decimals: 18,
    symbol: '',
    balance: BigNumber1.from('0'),
    address: '',
    price: '0'
  });
  const [selectedTokenInfo, setSelectedTokenInfo] = useState<TokenInfoType>(mainTokenInfo);
  const [tokensInfo, setTokensInfo] = useState<TokenInfoType[]>([]);
  const [customNftList, setCustomNftList] = useState<TokenInfoType[]>([]);

  const [amount, setAmount] = useState<string>('');
  const [address, setAddress] = useState('');
  const [targetChain, setTargetChain] = useState(1);
  const [nativeTokenSymbol, setNativeTokenSymbol] = useState<string[]>([]);
  const [resumingTransaction, setResumingTransaction] = useState(false);

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const { walletAddress } = useSelector((state: any) => state?.user);
  const { switchNetwork } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
    onError(error) {
      console.log(error.name);
      if (error.name === 'SwitchChainNotSupportedError') {
        warnToast('This wallet does not support programatic chain switching, use the wallets app.');
      } else if (error.name === 'erRejectedRequestError: ') {
        warnToast('User rejected chain switch');
      } else {
        warnToast('Unknown error has occured');
        console.error('Error', error);
      }
    }
  });
  const { chain: walletConnectChain } = useNetwork();
  const [chainId, setChainId] = useState('1');
  const [usingExchange, setUsingExchange] = useState('');
  const [exchange, setExchange] = useState<string>('');
  const [walletType, setWalletType] = useState<string>('');
  const [cexList, setCexlist] = useState<Array<string>>([]);
  const [txHistoryList, setTxHistoryList] = useState<Array<PreSendTransaction>>([]);
  const [resumableTransaction, setResumableTransaction] = useState<ActivityProps>();
  const [sdk, setSdk] = useState<PresendVerificationSDK | null>(null);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isResumableTransactionModalVisible, setIsResumableTransactionModalVisible] =
    useState(false);
  const transactionUtility = useMemo(
    () =>
      new TransactionUtility({
        debug: true
      }),
    []
  );
  const { ethereum } = window;
  // no-amount, no-balance, check-cex, no-cex, no-wallet, no-receiver
  const [txError, setTxError] = useState<Error>(Error.NONE);

  const handleClose = async () => {
    setTxResultStatus(true);
    await delay(2000);
    setTxProcessing(false);
    setTxResultStatus(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (!sdk && signer) {
      const tempSdk = new PresendVerificationSDK({
        signer,
        debug: true
      });

      setSdk(tempSdk);
    }
  }, [signer, sdk]);

  const getTokenUsdPrice = async (token: string, symbol: string) => {
    const ctx = {
      component: 'src/pages/Dashboard.getTokenUsdPrice',
      params: { token, symbol }
    };
    try {
      logger.log({
        level: LogLevel.INFO,
        message: 'Getting Token USD price',
        component: componentName,
        context: ctx
      });
      return await pricing.getPriceForTokenAddressAsync(token, symbol);
    } catch (err: unknown) {
      logger.error({
        message: '',
        component: componentName,
        context: ctx,
        error: err
      });
      console.error(err);
      return 0;
    }
  };

  if (nativeTokenSymbol.length === 0) {
    GetNativeTokenSymbolsAsync().then((symbols) => {
      setNativeTokenSymbol(symbols);
    });
  }

  const getTransferERC20Balances = useCallback(
    async (_chainId: string) => {
      const ctx = {
        component: 'src/pages/Dashboard.getTransferERC20Balances',
        params: { _chainId }
      };
      let newTokensInfo: TokenInfoType[] = [];
      const newCustomNftList: TokenInfoType[] = [];
      try {
        logger.log({
          level: LogLevel.INFO,
          message: 'Trying to get Transfer ERC20 Balances',
          context: ctx,
          component: componentName
        });
        const chain: any = chains.find((item) => item.chainId === parseInt(_chainId, 10));
        if (!chain) {
          return;
        }

        const tempProvider = new providers.JsonRpcProvider(chain.rpcUrl);
        const blockNumber = await tempProvider.getBlockNumber();
        const client = await axios.create({ baseURL: chain.scanApi.baseUrl });
        let txHistory: any[] = [];
        txHistory = await transactionFetcher(
          client,
          chain.scanApi.path,
          walletAddress,
          blockNumber
        );
        const tmpAddrs = new Set<string>([]);
        txHistory.forEach((tx: TxType) => {
          tmpAddrs.add(tx.contractAddress);
        });
        /* eslint no-underscore-dangle: ["error", { "allow": ["_mainTokenInfo"] }] */
        const nftAddrs = nftList.filter((item) => item.chainId === parseInt(_chainId, 10));

        await BlueBirdPromise.all(
          [...nftAddrs].map(async (item: any) => {
            const contract = new Contract(item.address, ERC721ABI, tempProvider);
            const balance = await contract.balanceOf(walletAddress);
            const name = await contract.name();
            const tempDecimals = 0;
            newCustomNftList.push({
              chain: chain.origin,
              name,
              decimals: tempDecimals,
              symbol: item.symbol,
              balance,
              address: item.address,
              price: '0'
            });
          })
        );

        setCustomNftList([...newCustomNftList]);

        const balance = await tempProvider.getBalance(walletAddress);
        const usdPrice = (await pricing.getPriceForPairAsync(`${chain.symbol} USD`)) || 0;
        const actualPrice = Number(bigNumberToString(balance, 18, false)) * usdPrice;

        const mainToken: TokenInfoType = {
          chain: chain.origin,
          name: chain.origin,
          decimals: 18,
          symbol: chain.symbol,
          balance,
          address: '',
          price: abbreviateNumber(actualPrice)
        };
        setMainTokenInfo({ ...mainToken });
        newTokensInfo.push({ ...mainToken });

        await BlueBirdPromise.all(
          [...tmpAddrs].map(async (token) => {
            try {
              const contract = new Contract(token, ERC20ABI, tempProvider);
              const symbol = await contract.symbol();
              const tempBalance = await contract.balanceOf(walletAddress);
              const tempDecimals = await contract.decimals();
              const balanceNum = Number(bigNumberToString(tempBalance, tempDecimals, false));
              if (!tempBalance.isZero() || balanceNum !== 0) {
                const name = await contract.name();
                const tempUsdPrice = await getTokenUsdPrice(token, symbol);
                const tempActualPrice = balanceNum * tempUsdPrice;
                newTokensInfo.push({
                  chain: chain.origin,
                  name,
                  decimals: tempDecimals,
                  symbol,
                  balance: tempBalance,
                  address: token,
                  price: abbreviateNumber(tempActualPrice)
                });
              }
            } catch (error) {
              console.error('Weltz Error: ', error);
            }
          })
        );

        console.log(newTokensInfo);
        newTokensInfo = newTokensInfo.sort((a, b) => (a.price < b.price ? 1 : -1));
        setTokensInfo([...newTokensInfo]);
      } catch (err: any) {
        console.error(err);
        setLoading(false);

        const warningError =
          err.code?.toLowerCase() === Error.NETWORK_ERROR
            ? WarningModalEnum.TooManyRequests
            : WarningModalEnum.OperationFailed;

        setWarningModalEnum(warningError);
        setWarningModalStatus(true);
        setReload(true);
      }
    },
    [walletAddress]
  );

  const openResumeTransactionModal = (transactionItem: ActivityProps) => {
    const ctx = {
      component: 'src/pages/Dashboard.openResumeTransactionModal',
      params: { transactionItem }
    };

    logger.log({
      level: LogLevel.INFO,
      message: 'Opening resume transaction modal',
      component: componentName,
      context: ctx
    });
    const presendTransaction: PreSendTransaction = transactionItem;
    const tokenInfo = tokensInfo.find(
      (x) => x.symbol.toLowerCase() === presendTransaction.targetTokenSymbol.toLowerCase()
    );

    if (!tokenInfo) {
      return;
    }

    setAmount(bigNumberToString(BigNumber1.from(transactionItem.targetTokenAmount), 18, false));

    const wallets: string[] = Object.values(WalletOptions);
    const isUsingExchange = !wallets.includes(transactionItem.targetCryptoApi);
    setUsingExchange(isUsingExchange ? 'yes' : 'no');

    if (isUsingExchange) {
      setExchange(transactionItem.targetCryptoApi);
    } else {
      setWalletType(transactionItem.targetCryptoApi);
    }

    setAddress(transactionItem.targetAddress);

    setSelectedTokenInfo(tokenInfo);
    setResumableTransaction(transactionItem);
    setModalStaus(true);

    setResumingTransaction(true);
  };

  const handleTxHistory = useCallback(
    async (chainName: string) => {
      const txsBySource =
        await transactionUtility.getTransactionHistoryBySourceAsync(walletAddress);
      setTxHistoryList(
        txsBySource
          .filter((item: PreSendTransaction) => item.targetNetwork === chainName)
          .sort((a, b) => (a.created < b.created ? 1 : -1))
      );
    },
    [walletAddress, transactionUtility]
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: string = (event.target as HTMLInputElement).value;
    setUsingExchange(newValue);
  };

  const handleCloseModal = () => setModalStaus(false);

  const handleChainId = useCallback(
    async (__chainId: string) => {
      setLoading(true);
      const extensionChain = chains.find((chain) => chain.chainId === parseInt(__chainId, 10));
      if (!extensionChain) {
        warnToast('Incorrect chain');
        setLoading(false);
      } else if (
        extensionChain &&
        ethereum &&
        walletConnectChain &&
        extensionChain.chainId !== walletConnectChain.id &&
        !Number.isNaN(walletConnectChain.id)
      ) {
        try {
          switchNetwork?.(extensionChain.chainId);
        } catch (error: any) {
          console.error(error);
          setLoading(false);
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error?.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainName: extensionChain.origin,
                    chainId: extensionChain.chainIdHex,
                    rpcUrls: [extensionChain.rpcUrl],
                    blockExplorerUrls: [extensionChain.blockExplorerUrl],
                    nativeCurrency: {
                      name: extensionChain.symbol,
                      symbol: extensionChain.symbol,
                      decimals: 18
                    }
                  }
                ]
              });
              switchNetwork?.(extensionChain.chainId);
            } catch (addError) {
              console.error(addError);
              setLoading(false);
              window.location.reload();
            }
          }
          return;
        }
      }
      setLoading(false);
    },
    [ethereum, switchNetwork, walletConnectChain]
  );

  useEffect(() => {
    async function init() {
      if (sdk) {
        console.log('OPTIONS:', await sdk.getSupportedExchangesAsync());
        enum CexOptions {
          Binancedotcom = 'binancedotcom',
          Binancedotus = 'binancedotus',
          Kucoin = 'kucoin',
          Cryptodotcom = 'cryptodotcom',
          CoinbaseExchange = 'coinbase_exchange',
          Kraken = 'kraken',
          NotListed = 'not_listed'
        }
        setCexlist(Object.values(CexOptions));
      }
      if (walletConnectChain) {
        const tempChainId = walletConnectChain.id.toString();
        const chainInfo = chains.find(
          (chain: ChainType) => chain.chainId === walletConnectChain?.id
        );
        if (!chainInfo) {
          handleChainId('1');
          return;
        }
        setChainId(tempChainId);

        if (!walletAddress) {
          navigate('/');
        }
        setLoading(true);
        await getTransferERC20Balances(tempChainId);

        await handleTxHistory(chainInfo.name);

        setLoading(false);
      }
    }
    init();
  }, [
    walletAddress,
    navigate,
    sdk,
    getTransferERC20Balances,
    handleChainId,
    handleTxHistory,
    walletConnectChain
  ]);

  useEffect(() => {
    if (!selectedTokenInfo.address) {
      return;
    }
    if (signer) {
      tokenContract = new Contract(selectedTokenInfo.address, ERC20ABI, signer);
    }
  }, [selectedTokenInfo.address, targetChain, ethereum, signer]);

  useInterval(async () => {
    if (walletConnectChain) {
      await getTransferERC20Balances(walletConnectChain.id.toString());
    }
  }, 15000);

  const transferERC20 = async (isTxRecovery: boolean) => {
    const ctx = {
      component: 'src/page/Dashboard.transferERC20',
      params: { isTxRecovery }
    };

    logger.log({
      level: LogLevel.INFO,
      message: 'Validating the transaction.',
      component: componentName,
      context: ctx
    });

    setResumingTransaction(false);
    if (!amount || amount === '0') {
      setTxError(Error.NO_AMOUNT);
      return;
    }

    const tokenAmount = Number(amount);

    if (tokenAmount < 0) {
      setTxError(Error.NEGATIVE_AMOUNT);
      return;
    }

    // Commented out to test out SDK using BNB Testnet
    if (
      selectedTokenInfo.address === '' &&
      utils.parseUnits(`${tokenAmount}`, selectedTokenInfo.decimals).gt(selectedTokenInfo.balance)
    ) {
      setTxError(Error.INSUFFICIENT_BALANCE);
      return;
    }
    if (selectedTokenInfo.address !== '') {
      const balance = await tokenContract.balanceOf(walletAddress);

      if (utils.parseUnits(`${tokenAmount}`, selectedTokenInfo.decimals).gt(balance)) {
        setTxError(Error.INSUFFICIENT_BALANCE);
        return;
      }
    }

    if (usingExchange === '') {
      setTxError(Error.CHECK_CEX);
      return;
    }

    if (walletType === '' && usingExchange === 'no') {
      setTxError(Error.NO_WALLET);
      return;
    }

    if (exchange === '' && usingExchange === 'yes') {
      setTxError(Error.NO_CEX);
      return;
    }

    if (address === '') {
      setTxError(Error.NO_RECEIVER);
      return;
    }

    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const selectedChain: any = chains.find(
      (chain: ChainType) => chain.chainId === parseInt(chainId, 10)
    );

    handleCloseModal();
    setLoadingText('We are ensuring that your transaction is safe!');
    setTxProcessing(true);

    if (usingExchange !== '' && sdk && signer) {
      try {
        let result: VerificationResponse;

        logger.log({
          level: LogLevel.INFO,
          message: 'Contacting PreSend SDK for transaction verification',
          component: componentName,
          context: ctx
        });

        if (isTxRecovery) {
          result = await sdk.resumeTransactionVerificationAsync(
            resumableTransaction?.transactionId ?? ''
          );
        } else {
          result = await sdk.verifyTransactionAsync({
            signer,
            payload: {
              sourceAddress: walletAddress,
              targetAddress: address.trim(),
              // eslint-disable-next-line no-nested-ternary
              targetCryptoApi: usingExchange === 'yes' ? exchange : walletType,
              targetNetwork: selectedChain.name,
              targetChainIdHex: selectedChain.chainIdHex.toLowerCase(),
              targetTokenAddress:
                selectedTokenInfo.address === '' ? 'native' : selectedTokenInfo.address,
              targetTokenSymbol: selectedTokenInfo.symbol,
              targetTokenAmount: tokenAmount
            }
          });
        }

        if (!result.verificationPassed) {
          logger.log({
            level: LogLevel.ERROR,
            message: 'Verification failed',
            component: componentName,
            context: ctx
          });
          result.verificationStepResponses.forEach((item: VerificationStepResponse) => {
            if (!item.verificationSuccess) {
              setWarningModalStatus(true);
              setTxProcessing(false);
              if (item.stepId === VerificationStepId.SANITY) {
                setWarningModalEnum(WarningModalEnum.SanityVerificationFailed);
              } else {
                setWarningModalEnum(WarningModalEnum.VerificationFailed);
              }
            }
          });
          return;
        }
      } catch (e: any) {
        logger.error({
          message: `Unexpected error while validating the transaction: ${e.message}`,
          component: componentName,
          error: e,
          context: ctx
        });
        setTxProcessing(false);
        if (e.code === 'ACTION_REJECTED') {
          return;
        }
        if (e.message) {
          setTxError(Error.CHECK_CEX);
          setWarningModalEnum(WarningModalEnum.VerificationFailed);
          setWarningModalStatus(true);
        }
        return;
      }

      setLoadingText(
        'Congratulations! You can safely send your transaction to the desired recipient wallet!'
      );
      // What is the purpose of this delay?
      // Nazar P - 2024-01-26
      await delay(2500);
    }
    setLoadingText('Payment Processing');
    // try {

    if (!selectedChain) {
      warnToast('Incorrect chain');
      return;
    }
    if (walletConnectChain && selectedChain.chainId !== walletConnectChain.id) {
      try {
        switchNetwork?.(selectedChain.chainId);
      } catch (
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        error: any
      ) {
        console.error(error);
        // This error code indicates that the chain has not been added to MetaMask
        // if it is not, then install it into the user MetaMask
        if (error?.code === 4902) {
          try {
            await ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: selectedChain.origin,
                  chainId: selectedChain.chainIdHex,
                  rpcUrls: [selectedChain.rpcUrl],
                  blockExplorerUrls: [selectedChain.blockExplorerUrl],
                  nativeCurrency: {
                    name: selectedChain.symbol,
                    symbol: selectedChain.symbol,
                    decimals: 18
                  }
                }
              ]
            });
            switchNetwork?.(selectedChain.chainId);
          } catch (e: any) {
            logger.error({
              message: `Unexpected error while installing valid chain to Metamask: ${e.message}`,
              component: componentName,
              error: e,
              context: ctx
            });
            console.error(e);
            window.location.reload();
          }
        }
        // continue regardless of error
      }
      window.location.reload();
    }
    if (selectedTokenInfo.address === '' && walletConnectChain && signer) {
      try {
        await (
          await signer.sendTransaction({
            to: address,
            value: utils.parseEther(`${tokenAmount}`),
            data: '0x'
          })
        ).wait();
        handleClose();
        await getTransferERC20Balances(walletConnectChain.id.toString());
        await handleTxHistory(selectedChain.name);
      } catch (e: any) {
        logger.error({
          message: `Unexpected error while sending the transaction: ${e.message}`,
          component: componentName,
          error: e,
          context: ctx
        });
        console.error(e);
        setTxProcessing(false);
        setTxError(Error.NONE);
        setWarningModalEnum(WarningModalEnum.PaymentFailed);
        setWarningModalStatus(true);
      }
      return;
    }

    try {
      const tx = await tokenContract.transfer(
        address,
        new BigNumber(tokenAmount)
          .times(new BigNumber(10).pow(selectedTokenInfo.decimals))
          .toString()
      );
      await tx.wait();
      handleClose();
      if (walletConnectChain) {
        await getTransferERC20Balances(walletConnectChain.id.toString());
        await handleTxHistory(selectedChain.name);
      }
    } catch (e: any) {
      logger.error({
        message: `Unexpected error while sending the token: ${e.message}`,
        component: componentName,
        error: e,
        context: ctx
      });
      console.error(e);
      setTxProcessing(false);
      setTxError(Error.NONE);
      setWarningModalEnum(WarningModalEnum.PaymentFailed);
      setWarningModalStatus(true);
    }
  };

  const fillInputs = (token: TokenInfoType) => {
    setAddress('');
    setTargetChain(chains.find((chain: ChainType) => chain.origin === token.chain)?.chainId || 1);
    setUsingExchange('');
    setExchange('');
    setWalletType('');
  };
  const { disconnect } = useDisconnect();
  const logOut = async () => {
    localStorage.removeItem('WALLET_CONNECTED');
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    disconnect();
    dispatch(disconnectWallet());
    navigate('/');
  };

  return (
    <DashboardComponent
      loading={loading}
      loadingText={loadingText}
      txProcessing={txProcessing}
      amount={amount}
      address={address}
      isVideoVisible={isVideoVisible}
      tokensInfo={tokensInfo}
      customNftList={customNftList}
      mainTokenInfo={mainTokenInfo}
      selectedTokenInfo={selectedTokenInfo}
      modalStatus={modalStatus}
      warningModalStatus={warningModalStatus}
      reload={reload}
      txResultStatus={txResultStatus}
      txError={txError}
      usingExchange={usingExchange}
      cexList={cexList}
      tabValue={tabValue}
      txHistoryList={txHistoryList}
      handleChange={handleChange}
      logOut={logOut}
      transferERC20={transferERC20}
      fillInputs={fillInputs}
      setAmount={setAmount}
      setAddress={setAddress}
      setIsVideoVisible={setIsVideoVisible}
      chainId={chainId}
      handleChainId={handleChainId}
      exchange={exchange}
      setExchange={setExchange}
      walletType={walletType}
      setWalletType={setWalletType}
      setModalStatus={setModalStaus}
      handleCloseModal={handleCloseModal}
      setWarningModalStatus={setWarningModalStatus}
      setSelectedTokenInfo={setSelectedTokenInfo}
      walletAddress={walletAddress}
      handleTabChange={handleTabChange}
      handleClose={handleClose}
      openResumeTransactionModal={openResumeTransactionModal}
      isResumableTransactionModalVisible={isResumableTransactionModalVisible}
      setIsResumableTransactionModalVisible={setIsResumableTransactionModalVisible}
      nativeTokenSymbols={nativeTokenSymbol}
      setTxError={setTxError}
      resumingTransaction={resumingTransaction}
      setResumingTransaction={setResumingTransaction}
      warningModalEnum={warningModalEnum}
    />
  );
}

export default Dashboard;
