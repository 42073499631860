import { EMPTY_VALUE } from '../../../constants';

interface HowToUseProps {
  isVideoVisible: boolean;
  setIsVideoVisible: (isVideoVisible: boolean) => void;
}

function HowToUse({ isVideoVisible, setIsVideoVisible }: HowToUseProps) {
  return (
    <div className="how-to-use">
      <div className="how-to">
        <h3>
          <span className="wider">Unsure of how</span>
          {EMPTY_VALUE}
          <br />
          to use PreSend?
        </h3>
        <button
          type="button"
          onClick={() => setIsVideoVisible(!isVideoVisible)}
          onKeyDown={() => setIsVideoVisible(!isVideoVisible)}
        >
          CLICK HERE
        </button>
      </div>
    </div>
  );
}

export default HowToUse;
