import { toast } from 'react-toastify';

const myToastId = 'myToastId';

export const normalToast = (message: string): void => {
  toast(message);
};

export const successToast = (message: string): void => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: myToastId,
    autoClose: 3000
  });
};

export const errorToast = (message: string): void => {
  toast.error(message, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: myToastId,
    autoClose: 3000
  });
};

export const warnToast = (message: string): void => {
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: myToastId,
    autoClose: 3000
  });
};

export const warnStaticToast = (message: React.ReactNode): void => {
  toast.warn(message, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: myToastId,
    autoClose: false
  });
};

export const infoToast = (message: string): void => {
  toast.info(message, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: myToastId,
    autoClose: 3000
  });
};
