import { useCallback, useContext, useEffect, useState } from 'react';
import { EMPTY_VALUE, TokenInfoType } from '../../../constants';
import ThemeContext from '../../../context/ThemeContext';
import { bigNumberToString } from '../../../helpers/methods';

interface MainTokenWrapperProps {
  mainTokenInfo: TokenInfoType;
  fillInputs: (token: TokenInfoType) => void;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  setModalStatus: (status: boolean) => void;
  setResumingTransaction: (resumable: boolean) => void;
}

enum ColorTheme {
  dark = 'dark',
  light = 'light'
}

function MainTokenWrapper({
  mainTokenInfo,
  fillInputs,
  setSelectedTokenInfo,
  setAmount,
  setModalStatus,
  setResumingTransaction
}: MainTokenWrapperProps) {
  const [isHover, setIsHover] = useState<boolean>(false);
  const [isDark, setIsDark] = useState<boolean>(false);
  const { theme } = useContext(ThemeContext);
  let logoImage;

  const decideTheme = useCallback(() => {
    if (theme === ColorTheme.dark) {
      setIsDark(true);
    } else {
      setIsDark(false);
    }
  }, [theme]);

  useEffect(() => {
    decideTheme();
  }, [theme, decideTheme]);
  if ((!isHover && !isDark) || (isHover && isDark)) {
    logoImage = <img src="/images/logo-white.png" alt="logo-black" className="presendLogo" />;
  } else {
    logoImage = <img src="/images/logo-black.png" alt="logo-white" className="presendLogo" />;
  }
  return (
    <div>
      <div className="main-token-wrapper">
        <div className="coin-logo">
          <img className="bg" src="/images/coin-logo-bg.png" alt="coin-bg" />
          <img
            className="icon"
            src={`/images/${
              mainTokenInfo.symbol.toLowerCase() === '' ? 'bnb' : mainTokenInfo.symbol.toLowerCase()
            }.png`}
            alt="coin-logo"
          />
        </div>
        <div className="toke-balance">
          {bigNumberToString(mainTokenInfo.balance, mainTokenInfo.decimals, false)}
          {EMPTY_VALUE}
          {mainTokenInfo.symbol}
        </div>
        <div className="usd-price">
          ${mainTokenInfo.price}
          {EMPTY_VALUE}
          USD
        </div>
        <button
          type="button"
          onClick={() => {
            fillInputs(mainTokenInfo);
            setSelectedTokenInfo(mainTokenInfo);
            setAmount('');
            setModalStatus(true);
            setResumingTransaction(false);
          }}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {logoImage}
        </button>
      </div>
      <div className="box-shadow"> </div>
    </div>
  );
}

export default MainTokenWrapper;
