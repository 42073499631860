import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginComponent from '../components/Login';
import { login } from '../actions';
import { LogLevel } from '../logging/core/LogLevel';
import DefaultLogger from '../logging/loggers/DefaultLogger';

const logger = new DefaultLogger();

function Login() {
  const navigate = useNavigate();
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const walletAddress = useSelector((state: any) => state.user.walletAddress);
  useEffect(() => {
    if (!walletAddress) {
      return;
    }
    logger.log({
      level: LogLevel.INFO,
      message: 'Logging in the user',
      component: 'Login.tsx',
      context: { component: 'src/pages/Login.useEffect', walletAddress }
    });
    localStorage.setItem('WALLET_CONNECTED', ' don');
    navigate('/dashboard');
  }, [walletAddress, navigate]);

  return <LoginComponent login={login} />;
}

export default Login;
