import { CONNECT_WALLET, DISCONNECT_WALLET } from '../constants/actionTypes';

export const connectWallet = (walletAddr: string) => ({
  type: CONNECT_WALLET,
  walletAddr
});

export const disconnectWallet = () => ({ type: DISCONNECT_WALLET });

export const login = async (open: any) => {
  await open();
};
