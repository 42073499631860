import { useState, useEffect, useRef, RefObject } from 'react';

export const useDetectOutsideClick = (el: RefObject<HTMLElement>, initialState: boolean) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (el.current !== null && !el.current.contains(e.target as Node)) {
        setIsActive(!isActive);
      }
    };

    if (isActive) {
      window.addEventListener('click', handler);
    }

    return () => {
      window.removeEventListener('click', handler);
    };
  }, [isActive, el]);

  return [isActive, setIsActive] as const;
};

export default function useInterval(callback: () => void, delay: null | number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const { current } = savedCallback;
      return current && current();
    }

    if (delay !== null) {
      tick();
      const id = setInterval(tick, delay);
      clearInterval(id);
    }
  }, [delay]);
}
