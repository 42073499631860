import { useNavigate } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import './index.css';

export enum WarningModalEnum {
  SanityVerificationFailed = 0,
  OperationFailed = 1,
  VerificationFailed = 2,
  PaymentFailed = 3,
  TooManyRequests = 4
}

interface WarningModalProps {
  open: boolean;
  reload: boolean;
  handleModal: (status: boolean) => void;
  id: WarningModalEnum;
}

interface ModalMessage {
  id: WarningModalEnum;
  info: string;
  message: JSX.Element;
  closeMessage: string;
}

const MODAL_MESSAGES_MAP: { [id: number]: ModalMessage } = {
  0: {
    id: WarningModalEnum.SanityVerificationFailed,
    info: 'Incorrect Wallet Address Alert',
    message: (
      <div>
        The recipient&apos;s wallet address is not compatible with the token you&apos;re trying to
        send. Please ensure the wallet address is correct. If you&apos;re unsure, double-check the
        recipient&apos;s details or contact PreSend support for assistance.
      </div>
    ),
    closeMessage: 'Close'
  },
  1: {
    id: WarningModalEnum.OperationFailed,
    info: 'Operation Failed',
    message: (
      <div>
        An unexpected issue occurred. Please try again later or contact support for assistance.
      </div>
    ),
    closeMessage: 'Close'
  },
  2: {
    id: WarningModalEnum.VerificationFailed,
    info: 'Unsafe Transaction Alert',
    message: (
      <div className="des">
        The recipient wallet is not compatible with the token/coin you are sending. Please ensure
        you are sending compatible tokens/coins to compatible platforms/wallets.
      </div>
    ),
    closeMessage: 'Stop Transaction'
  },
  3: {
    id: WarningModalEnum.PaymentFailed,
    info: 'Payment Failed',
    message: (
      <div className="des">
        <strong>Verification Successful. </strong>
        However, the transaction could not be completed. Please verify your account and consider
        contacting support for further assistance.
      </div>
    ),
    closeMessage: 'Close'
  },
  4: {
    id: WarningModalEnum.TooManyRequests,
    info: 'Request Rate Limit Reached',
    message: (
      <div className="des">
        Sorry, we&apos;ve received too many requests in a short time. Please try again in a few
        minutes.
      </div>
    ),
    closeMessage: 'Close'
  }
};

function WarningModal({ open, reload, handleModal, id }: WarningModalProps) {
  const navigate = useNavigate();
  const modalMessage = MODAL_MESSAGES_MAP[id];
  return (
    <Modal open={open}>
      <div className="warning-modal">
        <div className="modal-body">
          <img src="/images/warning.png" alt="warning" />
          <div className="title">WARNING!</div>
          <div className="info">{modalMessage.info}</div>
          {modalMessage.message}
          <Button
            style={{
              marginTop: '22px',
              padding: '7px',
              background: '#EC361D',
              width: '100%',
              fontWeight: '700',
              borderRadius: '19px',
              color: '#fff',
              fontSize: '14px',
              textTransform: 'none'
            }}
            onClick={() => {
              if (reload) {
                navigate('/');
              } else {
                handleModal(false);
              }
            }}
          >
            {reload ? 'RELOAD' : modalMessage.closeMessage}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default WarningModal;
