/* eslint-disable max-len */
import { ILogEntry } from '../core/ILogEntry';
import { LogLevel } from '../core/LogLevel';

export class DefaultLogger {
  /*
   * Default log limit is set to 500
   */
  private logLimit = 500;

  /**
   * Queue to store the logs
   */
  private logQueue: ILogEntry[] = [];

  /**
   * Storage key to save the logs
   */
  private STORAGE_KEY = 'debug_logs';

  /**
   * Component name that represent context where log was called
   */
  private component = 'presend-web';

  constructor(logLimit?: number) {
    this.logLimit = logLimit || this.logLimit;
    this.loadLogsFromLocalStorage();
  }

  private loadLogsFromLocalStorage() {
    const storedLogs = localStorage.getItem(this.STORAGE_KEY);
    if (storedLogs) {
      this.logQueue = JSON.parse(storedLogs);
    }
  }

  private saveLogsToLocalStorage() {
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.logQueue));
  }

  log({
    level,
    component = this.component,
    message,
    context,
    error
  }: {
    level: LogLevel;
    component?: string;
    message: string;
    context?: Record<string, any>;
    error?: Error;
  }) {
    const logEntry: ILogEntry = {
      time: new Date().toISOString(),
      level,
      message,
      component,
      context,
      error
    };

    switch (level) {
      case LogLevel.INFO:
        console.log(
          `%c[${logEntry.time}]%c[@presend-web/${logEntry.component}] %c[${logEntry.level}] ${logEntry.message}`,
          'color: #eab676;',
          'color: #76b5c5;',
          'color: #2ADC35;',
          { context: logEntry.context }
        );
        break;
      case LogLevel.DEBUG:
        console.debug(
          `%c[${logEntry.time}]%c[@presend-web/${logEntry.component}] %c[${logEntry.level}] ${logEntry.message}`,
          'color: #eab676;',
          'color: #76b5c5;',
          'color: #F1C940;',
          { context: logEntry.context }
        );
        break;
      case LogLevel.WARN:
        console.warn(
          `%c[${logEntry.time}]%c[@presend-web/${logEntry.component}] %c[${logEntry.level}] ${logEntry.message}`,
          'color: #eab676;',
          'color: #76b5c5;',
          'color: #F1C940;',
          { context: logEntry.context }
        );
        break;
      default:
        break;
    }

    this.logQueue.push(logEntry);
    // If queue length will exceed the limit - remove the first element
    if (this.logQueue.length > this.logLimit) {
      this.logQueue.shift();
    }

    this.saveLogsToLocalStorage();
  }

  error({
    message,
    component = this.component,
    error,
    context
  }: {
    message: string;
    component: string;
    error?: unknown;
    context?: Record<string, any>;
  }) {
    const logEntry: ILogEntry = {
      time: new Date().toISOString(),
      level: LogLevel.ERROR,
      component,
      message,
      context,
      error
    };

    console.error(
      `%c[${logEntry.time}]%c[@presend-web/${logEntry.component}] %c[${logEntry.level}] ${logEntry.message} ${logEntry.error}`,
      'color: #eab676;',
      'color: #76b5c5;',
      'color: #BF1418;',
      { context: logEntry.context }
    );
    this.logQueue.push(logEntry);

    // Trim the queue if it exceeds maxSize
    if (this.logQueue.length > this.logLimit) {
      this.logQueue.shift();
    }

    this.saveLogsToLocalStorage();
  }

  getLogs(): ILogEntry[] {
    return this.logQueue;
  }
}

export default DefaultLogger;
