/**
 * Represents various levels of logging.
 */
// eslint-disable-next-line import/prefer-default-export
export enum LogLevel {
  TRACE = 'TRACE',
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARN = 'WARNING',
  ERROR = 'ERROR',
  FATAL = 'FATAL'
}
