import { useRef } from 'react';
import { useDetectOutsideClick } from '../../../hooks';
import { chains } from '../../../config/config';
import './index.css';

interface DropdownNetworkProps {
  chainId: string;
  handleChainId: (data: string) => void;
}

function DropdownNetwork({ chainId, handleChainId }: DropdownNetworkProps) {
  const dropDownRef = useRef(null);
  const [showDropdown, toggleDropdown] = useDetectOutsideClick(dropDownRef, false);

  return (
    <button
      type="button"
      className="dropdown-network-wrapper"
      ref={dropDownRef}
      onClick={() => toggleDropdown(!showDropdown)}
      onKeyDown={() => toggleDropdown(!showDropdown)}
    >
      {chains.map((item) => {
        let text = item.origin;
        if (item.origin === 'BNB Smart Chain Mainnet') {
          text = 'BSC Mainnet';
        } else if (item.origin === 'Ethereum Mainnet') {
          text = 'ETH Mainnet';
        }
        return (
          <div className="network-title" key={item.chainId}>
            {item.chainId === parseInt(chainId, 10) && (
              <div>
                <img
                  className="chain-logo"
                  src={`/images/${item.symbol.toLowerCase()}.png`}
                  alt=""
                />
                {text}
              </div>
            )}
          </div>
        );
      })}
      <img className="arrow" src="/images/arrow-down.png" alt="" />
      {showDropdown && (
        <div className="network-list">
          {chains.map((item) => (
            <button
              type="button"
              key={item.chainId}
              onClick={() => handleChainId(item.chainId.toString())}
            >
              <img className="chain-logo" src={`/images/${item.symbol.toLowerCase()}.png`} alt="" />
              {item.origin}
            </button>
          ))}
        </div>
      )}
      {showDropdown && <div className="bg" />}
    </button>
  );
}

export default DropdownNetwork;
