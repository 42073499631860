import { TokenInfoType } from '../../../constants';
import AssetButton from '../../Buttons/AssetButton';

interface AssetsWrapperProps {
  tokensInfo: Array<TokenInfoType>;
  setSelectedTokenInfo: (token: TokenInfoType) => void;
  fillInputs: (token: TokenInfoType) => void;
  setAmount: (amount: string) => void;
  setModalStatus: (status: boolean) => void;
  customNftList: Array<TokenInfoType>;
  setResumingTransaction: (resuming: boolean) => void;
}

function AssetsWrapper({
  tokensInfo,
  setSelectedTokenInfo,
  fillInputs,
  setAmount,
  setModalStatus,
  customNftList,
  setResumingTransaction
}: AssetsWrapperProps) {
  return (
    <div className="assets-wrapper">
      {tokensInfo.map((token: TokenInfoType) => (
        <AssetButton
          isCustomNft={false}
          token={token}
          setSelectedTokenInfo={setSelectedTokenInfo}
          fillInputs={fillInputs}
          setAmount={setAmount}
          setModalStatus={setModalStatus}
          setResumingTransaction={setResumingTransaction}
        />
      ))}
      {customNftList.map((token: TokenInfoType) => (
        <AssetButton
          isCustomNft
          token={token}
          setSelectedTokenInfo={setSelectedTokenInfo}
          fillInputs={fillInputs}
          setAmount={setAmount}
          setModalStatus={setModalStatus}
          setResumingTransaction={setResumingTransaction}
        />
      ))}
    </div>
  );
}

export default AssetsWrapper;
