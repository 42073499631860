import { useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useDetectOutsideClick } from '../../../hooks';
import './index.css';
import { EMPTY_VALUE } from '../../../constants';

interface DropdownwalletProps {
  walletList: string[];
  walletType: string;
  setWalletType: (data: string) => void;
  disabled: boolean;
}

function wallet2Dom(wallet: string) {
  let dom;
  if (wallet === '') {
    dom = 'Select Type of Wallet';
  } else if (wallet === 'metamask') {
    dom = (
      <div className="wallet-title">
        <img src="/images/metamask.png" alt="metamask" />
        {EMPTY_VALUE}
        <span>Metamask</span>
      </div>
    );
  } else if (wallet === 'ledger') {
    dom = (
      <div className="wallet-title">
        <img src="/images/ledger.png" alt="Ledger" />
        {EMPTY_VALUE}
        <span>Ledger</span>
      </div>
    );
  } else if (wallet === 'coinbase_wallet') {
    dom = (
      <div className="wallet-title">
        <img src="/images/Coinbase-Wallet.png" alt="Coinbase-Wallet" />
        {EMPTY_VALUE}
        <span>Coinbase - Wallet</span>
      </div>
    );
  } else if (wallet === 'opera_wallet') {
    dom = (
      <div className="wallet-title">
        <img src="/images/Opera-Wallet.png" alt="Opera-Wallet" />
        {EMPTY_VALUE}
        <span>Opera - Wallet</span>
      </div>
    );
  } else if (wallet === 'cryptodotcom_wallet') {
    dom = (
      <div className="wallet-title">
        <img src="/images/crypto.com-Wallet.png" alt="Crypto.com-Wallet" />
        {EMPTY_VALUE}
        <span>Crypto.com - Wallet</span>
      </div>
    );
  } else if (wallet === 'trust_wallet') {
    dom = (
      <div className="wallet-title">
        <img src="/images/Trust-Wallet.png" alt="Trust-Wallet" />
        {EMPTY_VALUE}
        <span>Trust Wallet - Extension</span>
      </div>
    );
  } else if (wallet === 'brave_wallet') {
    dom = (
      <div className="wallet-title">
        <img src="/images/brave-lion.png" alt="Brave-Wallet" />
        {EMPTY_VALUE}
        <span>Brave Wallet</span>
      </div>
    );
  } else if (wallet === 'not_listed') {
    dom = (
      <div className="wallet-title">
        <img src="/images/not_listed.png" alt="crypto" />
        {EMPTY_VALUE}
        <span>Other</span>
      </div>
    );
  } else {
    return null;
  }
  return dom;
}

function DropdownWallet({ walletList, walletType, setWalletType, disabled }: DropdownwalletProps) {
  const dropDownRef = useRef(null);
  const [showDropdown, toggleDropdown] = useDetectOutsideClick(dropDownRef, false);

  return (
    <div className="wallet-select-wrapper">
      <button
        type="button"
        className={`title ${walletType !== '' && 'active'}`}
        ref={dropDownRef}
        onClick={() => toggleDropdown(!showDropdown)}
        onKeyDown={() => toggleDropdown(!showDropdown)}
        disabled={disabled}
      >
        {wallet2Dom(walletType)}
        <div className="arrow">
          <KeyboardArrowDownIcon color="inherit" fontSize="inherit" />
        </div>
      </button>
      {showDropdown && (
        <div className="wallet-list scrollbar">
          {walletList.map((item: string) => (
            <button className="title" type="button" key={item} onClick={() => setWalletType(item)}>
              {wallet2Dom(item) !== null && wallet2Dom(item)}
            </button>
          ))}
        </div>
      )}
      {walletType === 'not_listed' && (
        <div className="wallet-alert">
          This transaction cannot be protected because PreSend only supports specific Wallets &
          Centralized Exchanges at the moment.
        </div>
      )}
    </div>
  );
}

export default DropdownWallet;
