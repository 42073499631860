import { PreSendTransaction } from '@presend/verification-dev';
import ActivityFilterWrapper from '../ActivityFilterWrapper';
import ActivityButton from '../../Buttons/ActivityButton';

interface ActivityProps extends PreSendTransaction {
  txType: string;
}

interface ActivityWrapperProps {
  walletAddress: string;
  handleSwitchValue: (value: string) => void;
  handleFilterValue: (value: string) => void;
  activeSwitch: string;
  desc: boolean;
  setDesc: (value: boolean) => void;
  filteredTxs: any[];
  ActivitySortType: any;
  openResumeTransactionModal: (transactionItem: ActivityProps) => void;
}

function ActivityWrapper({
  walletAddress,
  handleSwitchValue,
  handleFilterValue,
  activeSwitch,
  desc,
  setDesc,
  filteredTxs,
  ActivitySortType,
  openResumeTransactionModal
}: ActivityWrapperProps) {
  return (
    <div className="activities-wrapper">
      <ActivityFilterWrapper
        handleSwitchValue={handleSwitchValue}
        handleFilterValue={handleFilterValue}
        desc={desc}
        setDesc={setDesc}
        activeSwitch={activeSwitch}
        ActivitySortType={ActivitySortType}
      />
      <div
        className="activities"
        style={
          activeSwitch === ActivitySortType.Timeline ? { marginTop: '35px' } : { marginTop: '18px' }
        }
      >
        {filteredTxs.length > 0 &&
          filteredTxs?.map((item: ActivityProps) => (
            <ActivityButton
              item={item}
              walletAddress={walletAddress}
              openResumeTransactionModal={openResumeTransactionModal}
            />
          ))}
      </div>
    </div>
  );
}

export default ActivityWrapper;
