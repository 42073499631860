import './login.css';
import { useState } from 'react';
import { useWeb3Modal } from '@web3modal/react';

interface LoginProps {
  login: (open: any) => Promise<void>;
}

function Login({ login }: LoginProps) {
  const { open } = useWeb3Modal();
  const [TOSAccepted, setTOSAccepted] = useState<boolean>(false);
  return (
    <div className="login-body text-center">
      <main className="form-signin login-form">
        <img className="logo" src="/images/wallet-png.png" alt="" />
        <div className="flex flex-row pt-[117px] pb-4 w-[300px]">
          <input
            type="checkbox"
            id="tos"
            name="tos"
            value="tos"
            onChange={() => setTOSAccepted(!TOSAccepted)}
          />
          <p className="pl-4">
            I Acknowledge that I have read and agree to the
            <a
              target="_blank"
              rel="noreferrer"
              href="https://presend-company-documents.gitbook.io/presend-legal/presend-terms-of-use"
            >
              {' '}
              Terms of Service
            </a>
            .
          </p>
        </div>
        <button
          id="btn-login"
          className="btn btn-lg btn-login"
          type="submit"
          disabled={!TOSAccepted}
          onClick={() => login(open)}
        >
          Connect Wallet
        </button>
      </main>
    </div>
  );
}

export default Login;
