import { useRef } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useDetectOutsideClick } from '../../../hooks';
import './index.css';
import { EMPTY_VALUE } from '../../../constants';

interface DropdownCexProps {
  cexList: string[];
  exchange: string;
  setExchange: (data: string) => void;
  disabled: boolean;
}

function cex2Dom(cex: string) {
  let dom;
  if (cex === '') {
    dom = 'Select Type of CEX';
  } else if (cex === 'coinbase_exchange') {
    dom = (
      <div className="cex-title">
        <img src="/images/coinbase.png" alt="coinbase" />
        {EMPTY_VALUE}
        <span>Coinbase.com Exchange</span>
      </div>
    );
  } else if (cex === 'cryptodotcom') {
    dom = (
      <div className="cex-title">
        <img src="/images/crypto.png" alt="crypto" />
        {EMPTY_VALUE}
        <span>Crypto.com Exchange</span>
      </div>
    );
  } else if (cex === 'kucoin') {
    dom = (
      <div className="cex-title">
        <img src="/images/kucoin.png" alt="kucoin" />
        {EMPTY_VALUE}
        <span>Kucoin.com Exchange</span>
      </div>
    );
  } else if (cex === 'binancedotus') {
    dom = (
      <div className="cex-title">
        <img src="/images/binance.png" alt="binance" />
        {EMPTY_VALUE}
        <span>Binance.us Exchange</span>
      </div>
    );
  } else if (cex === 'binancedotcom') {
    dom = (
      <div className="cex-title">
        <img src="/images/binance.png" alt="binancecom" />
        {EMPTY_VALUE}
        <span>Binance.com Exchange</span>
      </div>
    );
  } else if (cex === 'kraken') {
    dom = (
      <div className="cex-title">
        <img src="/images/kraken.png" alt="kraken" />
        {EMPTY_VALUE}
        <span>Kraken Exchange</span>
      </div>
    );
  } else if (cex === 'not_listed') {
    dom = (
      <div className="cex-title">
        <img className="h-auto" src="/images/not_listed.png" alt="not listed" />
        {EMPTY_VALUE}
        <span>Other</span>
      </div>
    );
  } else {
    return null;
  }
  return dom;
}

function DropdownCex({ cexList, exchange, setExchange, disabled }: DropdownCexProps) {
  const dropDownRef = useRef(null);
  const [showDropdown, toggleDropdown] = useDetectOutsideClick(dropDownRef, false);

  return (
    <div className="cex-select-inner">
      <button
        type="button"
        className={`title ${exchange !== '' && 'active'}`}
        ref={dropDownRef}
        onClick={() => toggleDropdown(!showDropdown)}
        onKeyDown={() => toggleDropdown(!showDropdown)}
        disabled={disabled}
      >
        {cex2Dom(exchange)}
        <div className="arrow">
          <KeyboardArrowDownIcon color="inherit" fontSize="inherit" />
        </div>
      </button>
      {showDropdown && (
        <div className="cex-list scrollbar">
          {cexList.map((item: string) => (
            <button className="title" type="button" key={item} onClick={() => setExchange(item)}>
              {cex2Dom(item) !== null && cex2Dom(item)}
            </button>
          ))}
        </div>
      )}
      {exchange === 'not_listed' && (
        <div className="exchange-alert">
          This transaction cannot be protected because PreSend only supports specific Wallets &
          Centralized Exchanges at the moment.
        </div>
      )}
    </div>
  );
}

export default DropdownCex;
