import { useState } from 'react';
import './index.css';

interface FitlerProps {
  handleFilterValue: (e: string) => void;
}

enum Filters {
  SAVED = 'SAVED',
  PROTECTED = 'PROTECTED',
  INCOMPLETE = 'INCOMPLETE',
  ALL = 'ALL'
}

function ActivityFilter({ handleFilterValue }: FitlerProps) {
  const [filter, setFilter] = useState<string>('');

  const changeFilter = (e: string) => {
    setFilter(e);
    handleFilterValue(e);
  };

  return (
    <div className="activity-filter">
      {Object.values(Filters).map((filterEnum) => {
        const filterName = filterEnum.toLowerCase();

        let className = filterName;
        if (!filterName) {
          className += ' actives';
        } else if (filter === filterName) {
          className += ' actives active';
        }
        return (
          <button
            key={filterName}
            type="button"
            className={`filter ${className}`}
            onClick={() => changeFilter(filterName)}
          >
            {filterEnum}
          </button>
        );
      })}
    </div>
  );
}

export default ActivityFilter;
