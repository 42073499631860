import { AxiosInstance } from 'axios';
import { delay } from '../helpers/methods';

const transactionFetcher = async (
  client: AxiosInstance,
  path: string,
  walletAddress: string,
  blockNumber: number
) => {
  let txHistory: any[] = [];
  let page = 1;
  let current: any[] = [];
  let walletTransactionsRequest: any;
  let success: boolean;
  let retryCount: number;
  const maxRetry = 3;

  while (current.length > 0 || page === 1) {
    success = false;
    retryCount = 0;
    while (!success && retryCount <= maxRetry) {
      // eslint-disable-next-line no-await-in-loop
      walletTransactionsRequest = await client.get(
        `${path}`
          .replace('walletAddr', walletAddress)
          .replace('page=1', `page=${page}`)
          .replace('blockNumber', blockNumber.toString())
      );
      if (walletTransactionsRequest.data.message === 'NOTOK') {
        retryCount += 1;
        // eslint-disable-next-line no-await-in-loop
        await delay(1000);
      } else {
        success = true;
      }
    }
    if (retryCount > maxRetry) {
      throw new Error('Rate Limiting Error');
    }
    current = walletTransactionsRequest.data.result;
    txHistory = [...txHistory, ...current];
    page += 1;
  }
  return txHistory;
};

export default transactionFetcher;
